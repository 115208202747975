export interface IFleetQuickFilters {
  vehicleNames: string[];
  licensePlateNumbers: string[];
  licensePlateStates: string[];
  tagNumbers: string[];
  missingItems: string[] | number[];
}
export const fleetQuickFilterInitialValues: IFleetQuickFilters = {
  vehicleNames: [],
  licensePlateNumbers: [],
  licensePlateStates: [],
  tagNumbers: [],
  missingItems: []
};
export interface ITripQuickFilters {
  tripIds: number[];
  vehicleNames: string[];
  licensePlateNumbers: string[];
  licensePlateStates: string[];
  tollAmount?: { from: number; to: number };
}
export const tripQuickFilterInitialValues: ITripQuickFilters = {
  tripIds: [],
  vehicleNames: [],
  licensePlateNumbers: [],
  licensePlateStates: [],
  tollAmount: { from: 0, to: 0 }
};

export interface ITransactionsQuickFilters {
  transactionsType: string[];
  licensePlateNumbers: string[];
  tagNumbers: string[];
}
export const transactionsQuickFilterInitialValues: ITransactionsQuickFilters = {
  transactionsType: [],
  licensePlateNumbers: [],
  tagNumbers: []
};
