export type LabelType = 'LPT-TOLL' | 'MAX-TOLL' | 'MISCHARGE' | 'XTA-DUPE';
export const typeGroup = {
  'LPT-TOLL': ['LPT-TOLL'],
  'MAX-TOLL': ['MAX-TOLL'],
  MISCHARGE: ['OVR-TOLL', 'DLY-POST', 'CRG-TOLL'],
  'XTA-DUPE': ['TLR-DUPE', 'XTA-DUPE']
};

export const labelMapping: Record<LabelType, string> = {
  'LPT-TOLL': 'License Plate Tolls',
  'MAX-TOLL': 'Max Tolls',
  MISCHARGE: 'Mischarge',
  'XTA-DUPE': 'Duplicate Tolls'
};

export const includedDuplicateHeadings = [
  'action_type',
  'record_id',
  'dispute_type',
  'toll_posted_date',
  'toll_agency_name',
  'posted_toll_entry_date_time',
  'posted_toll_entry_plaza',
  'posted_toll_exit_date_time', // duplicate in sheet
  'posted_toll_exit_plaza', // duplicate in sheet
  'posted_by_tag', // duplicate in sheet
  'posted_by_license_plate', // duplicate in sheet
  'toll_class',
  'posted_toll_amount', // duplicate in sheet
  'expected_toll',
  'disputable_amount',
  'dispute_details',
  'vehicle_name',
  'vehicle_type', // missing in data present in sheet
  'vin',
  'posted_date', // missing in data present in sheet
  'same_vehicle',
  'posted_toll_vehicle_name',
  'posted_toll_vehicle_type',
  'posted_vin' // missing in data but insheet
];

export const includedLicensePlateHeadings = [
  'action_type',
  'record_id',
  'dispute_type',
  'toll_posted_date',
  'toll_agency_name',
  'posted_toll_entry_date_time',
  'posted_toll_entry_plaza',
  'posted_toll_exit_date_time',
  'posted_toll_exit_plaza',
  'posted_by_tag',
  'posted_by_license_plate',
  'toll_class',
  'posted_toll',
  'expected_toll',
  'disputable_amount',
  'dispute_details',
  'vehicle_name',
  'vin',
  'trip_id',
  'license_plate_cost', // missing in data
  'tag_cost',
  'tag_number',
  'tag_agency_name'
];

export const includedMaxTollHeadings = [
  'action_type',
  'record_id',
  'dispute_type',
  'toll_posted_date',
  'toll_agency_name',
  'posted_toll_entry_date_time',
  'posted_toll_entry_plaza',
  'posted_toll_exit_date_time',
  'posted_toll_exit_plaza',
  'posted_by_tag',
  'posted_by_license_plate',
  'toll_class',
  'posted_toll',
  'expected_toll',
  'disputable_amount',
  'dispute_details',
  'vehicle_name',
  'vin',
  'trip_id',
  'start_toll_name',
  'start_toll_road',
  'start_ts',
  'start_lat',
  'start_long',
  'end_toll_name',
  'end_toll_road',
  'end_ts',
  'end_lat',
  'end_long'
];

export const includedMischargeHeadings = [
  'action_type',
  'record_id',
  'dispute_type',
  'toll_posted_date',
  'toll_agency_name',
  'posted_toll_entry_date_time',
  'posted_toll_entry_plaza',
  'posted_toll_exit_date_time',
  'posted_toll_exit_plaza',
  'posted_by_tag',
  'posted_by_license_plate',
  'toll_class',
  'posted_toll',
  'expected_toll',
  'disputable_amount',
  'dispute_details',
  'vehicle_name',
  'vin',
  'trip_id'
];

export const columnWidths: { [key: string]: string } = {
  action_type: '200px',
  record_id: '120px',
  dispute_type: '150px',
  toll_posted_date: '170px',
  toll_agency_name: '250px',
  posted_toll_entry_date_time: '250px',
  posted_toll_entry_plaza: '250px',
  posted_toll_exit_date_time: '250px',
  posted_toll_exit_plaza: '250px',
  posted_by_tag: '180px',
  posted_by_license_plate: '250px',
  toll_class: '150px',
  posted_toll_amount: '200px',
  expected_toll: '150px',
  disputable_amount: '200px',
  dispute_details: '300px',
  vehicle_name: '150px',
  vehicle_type: '150px',
  vin: '150px',
  posted_date: '170px',
  same_vehicle: '150px',
  posted_toll_vehicle_name: '250px',
  posted_toll_vehicle_type: '250px',
  posted_vin: '150px',
  posted_toll: '150px',
  trip_id: '120px',
  start_toll_name: '170px',
  start_toll_road: '170px',
  start_ts: '160px',
  start_lat: '120px',
  start_long: '130px',
  end_toll_name: '150px',
  end_toll_road: '150px',
  end_ts: '160px',
  end_lat: '120px',
  end_long: '120px'
};

export const dataAlignment: { [key: string]: React.CSSProperties['textAlign'] } = {
  action_type: 'left',
  record_id: 'right',
  dispute_type: 'left',
  toll_posted_date: 'left',
  toll_agency_name: 'left',
  posted_toll_entry_date_time: 'left',
  posted_toll_entry_plaza: 'left',
  posted_toll_exit_date_time: 'left',
  posted_toll_exit_plaza: 'left',
  posted_by_tag: 'right',
  posted_by_license_plate: 'left',
  toll_class: 'right',
  posted_toll_amount: 'right',
  expected_toll: 'right',
  disputable_amount: 'right',
  dispute_details: 'left',
  vehicle_name: 'left',
  vehicle_type: 'left',
  vin: 'center',
  posted_date: 'left',
  same_vehicle: 'left',
  posted_toll_vehicle_name: 'left',
  posted_toll_vehicle_type: 'left',
  posted_vin: 'center',
  posted_toll: 'right',
  trip_id: 'right',
  start_toll_name: 'left',
  start_toll_road: 'left',
  start_ts: 'right',
  start_lat: 'right',
  start_long: 'right',
  end_toll_name: 'left',
  end_toll_road: 'left',
  end_ts: 'right',
  end_lat: 'right',
  end_long: 'right'
};
