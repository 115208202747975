/* eslint-disable */
//  no-param-reassign
/* eslint-disable import/no-cycle */
import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { UnionDataInterface } from '@common/types/data';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { ETableName } from '@common/utils/enums';
import api from '@api/services/common';

export interface ITable {
  name: ETableName | undefined;
  data: UnionDataInterface[] | undefined;
  isLoading: boolean;
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
  tagIssuingAgencyList?: {
    id: string;
    name: string;
    is_toll_consolidator: boolean;
    is_tag_issuing_agency: boolean;
    state_name: string;
  }[];
  fleetQuickFilters?: {
    names: string[];
    plates: string[];
    states: {
      id: number | null;
      state_code: string;
      state_name: string;
    }[];
    billing_statuses: string[];
    tags: string[];
    missingAssetsAndTransponders: string[];
  };
  tripQuickFilters?: {
    amount: {
      max_amount: number;
      min_amount: number;
    };
    names: string[];
    plates: string[];
    states: {
      id: number | null;
      state_code: string;
      state_name: string;
    }[];
  };
  transactionsQuickFilters?: {
    transactions: string[];
    plates: string[];
    tags: string[];
  };
  error: FetchBaseQueryError | SerializedError | undefined;
  fetch?: boolean;
}

export const tableIState: ITable = {
  name: undefined,
  data: undefined,
  isLoading: true,
  isFetching: true,
  isSuccess: false,
  isError: false,
  error: undefined,
  tagIssuingAgencyList: [],
  fleetQuickFilters: {
    names: [],
    plates: [],
    states: [],
    billing_statuses: [],
    tags: [],
    missingAssetsAndTransponders: []
  },
  tripQuickFilters: {
    amount: {
      max_amount: 1000,
      min_amount: 0
    },
    names: [],
    plates: [],
    states: []
  },
  transactionsQuickFilters: {
    transactions: [],
    plates: [],
    tags: []
  },
  fetch: false
};

export const getIssuingAgency = createAsyncThunk('getIssuingAgency', async () => {
  const tagIssuingAgencyRes = await api.get(`/customer-onboard/issue-agency`);
  tagIssuingAgencyRes.data.sort((a: any, b: any) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
  tagIssuingAgencyRes.data.unshift({ name: 'None', id: null, is_toll_consolidator: false });
  return tagIssuingAgencyRes.data;
});

export const getFleetQuickFilters = createAsyncThunk('getFleetQuickFilters', async () => {
  const fleetQuickFiltersRes = await api.get(`/vehicle/fleet-filters`);
  return fleetQuickFiltersRes?.data;
});

export const getTransactionQuickFilters = createAsyncThunk('getTransactionQuickFilters', async (isActualTollTab: boolean) => {
  const fleetQuickFiltersRes = await api.get(`/gps-tolls/transactions-filters?isActualTollTab=${isActualTollTab}`);
  return fleetQuickFiltersRes?.data;
});

export const getTripQuickFilters = createAsyncThunk('getTripQuickFilters', async () => {
  const tripQuickFiltersRes = await api.get(`/vehicle/trip-filters`);
  return tripQuickFiltersRes?.data;
});

export const tableSlice = createSlice({
  name: 'table in view',
  initialState: tableIState,
  reducers: {
    setTable: (state, action: PayloadAction<Omit<ITable, 'name'> & { name?: ETableName }>) => {
      const { name, data, isLoading, isFetching, isSuccess, isError, error, fetch } =
        action.payload;
      if (name) {
        state.name = name;
      }
      state.data = data;
      state.error = error;
      state.isError = isError;
      state.isLoading = isLoading;
      state.isFetching = isFetching;
      state.isSuccess = isSuccess;
      state.fetch = fetch || false;
    },
    setTableName: (state, action: PayloadAction<ETableName>) => {
      state.name = action.payload;
    },
    refetchTableData: (state, action: PayloadAction<boolean>) => {
      state.fetch = action.payload;
    },
    clearTableData: state => {
      state.data = undefined;
    },
    setLoaders: (state, action: PayloadAction<[boolean | void, boolean]>) => {
      const [loading, fetching] = action.payload;
      state.isLoading = loading || state.isLoading;
      state.isFetching = fetching;
    },
    clearTable: () => tableIState
  },
  extraReducers(builder) {
    builder.addCase(getIssuingAgency.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getIssuingAgency.fulfilled, (state, action) => {
      state.tagIssuingAgencyList = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getFleetQuickFilters.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getFleetQuickFilters.fulfilled, (state, action) => {
      state.fleetQuickFilters = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getTransactionQuickFilters.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getTransactionQuickFilters.fulfilled, (state, action) => {
      state.transactionsQuickFilters = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getTripQuickFilters.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getTripQuickFilters.fulfilled, (state, action) => {
      state.tripQuickFilters = action.payload;
      state.isLoading = false;
    });
  }
});

export const { setTable, setTableName, clearTable, clearTableData, refetchTableData } =
  tableSlice.actions;
export default tableSlice.reducer;
